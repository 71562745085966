import IconButton from "@mui/material/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    Collapse,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    InputLabel,
    ListItemText,
    Menu,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    styled,
    TextField,
    Tooltip
} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import {ExpandLess, ExpandMore, Search} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import projectPageStore from "../../../flux/project/page/ProjectPageStore";
import Immutable from "immutable";
import segmentListStore from "../../../flux/segment/list/SegmentListStore";
import SegmentListFilter, {ISegmentListFilter} from "../../../flux/segment/list/SegmentListFilter";
import Project from "../../../model/Project";
import {NavigateFunction, useNavigate} from "react-router-dom";
import segmentEditorStore from "../../../flux/segment/editor/SegmentEditorStore";
import {getSegmentPath} from "../../../routes/EditorRoute";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {User} from "../../../model/User";
import usersListStore from "../../../flux/users/list/UsersListStore";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";

const icon = <CheckBoxOutlineBlankIcon fontSize={"small"}/>
const checkedIcon = <CheckBoxIcon fontSize={"small"}/>

const CustomChip = styled(Chip)({
    maxWidth: 200
})

export default function EditorFiltersView() {

    const initialProjectState = projectPageStore.getState();
    const initialFilterState = segmentListStore.getState().filter;
    const initialUsersState = usersListStore.getState();

    const [project, setProject] = useState(initialProjectState.project);
    const [users, setUsers] = useState(getAuthorOptions(
        initialUsersState.administrators?.list,
        initialUsersState.usersWithPermissions?.list));
    const [selectedWorkflowSteps, setSelectedWorkflowSteps] = useState<Immutable.List<number>>(Immutable.List());
    const [filterMenuOpen, setFilterMenuOpen] = useState(false);
    const [filterMenuSegmentStatusOpen, setFilterMenuSegmentStatusOpen] = useState(false);
    const [filterMenuSegmentIdContentOpen, setFilterMenuSegmentIdContentOpen] = useState(false);
    const [filterMenuCommentsOpen, setFilterMenuCommentsOpen] = useState(false);
    const [filterMenuLocksOpen, setFilterMenuLocksOpen] = useState(false);
    const [filterMenuAuthorOpen, setFilterMenuAuthorOpen] = useState(false);
    const [filterMenuTimeOpen, setFilterMenuTimeOpen] = useState(false);
    const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(null);
    const [filter, setFilter] = useState(initialFilterState);

    const navigate = useNavigate();

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            setProject(state.project);
        });

        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            const workflowIds = state.filter.workflowIds;
            setFilter(state.filter);
            setSelectedWorkflowSteps(workflowIds ? Immutable.List(workflowIds) : Immutable.List());
        });

        const usersListListener = usersListStore.addListener(() => {
            const state = usersListStore.getState();
            setUsers(getAuthorOptions(state.administrators?.list, state.usersWithPermissions?.list))
        });

        return () => {
            segmentListListener.remove();
            projectPageListener.remove();
            usersListListener.remove();
        }
    }, []);

    return (
        <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
            <IconButton onClick={e =>
                handleFilterButtonClicked(e, setFilterMenuAnchor, setFilterMenuOpen)}>
                <FilterAltIcon/>
            </IconButton>
            {drawSelectedFilters(project, users, filter, selectedWorkflowSteps, navigate)}
            <Menu open={filterMenuOpen} anchorEl={filterMenuAnchor}
                  onClose={() => handleFilterMenuClosed(setFilterMenuAnchor, setFilterMenuOpen)}>
                <List>
                    <ListItemButton onClick={() =>
                        handleGroupClicked(filterMenuSegmentIdContentOpen, setFilterMenuSegmentIdContentOpen)}>
                        <ListItemText primary={"Segment ID content"} key={"segment-id-content"}/>
                        {filterMenuSegmentIdContentOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={filterMenuSegmentIdContentOpen} key={"segment-id-content-collapse"}>
                        <Stack padding={1}>
                            <TextField size={"small"} placeholder={"Search..."}
                                       value={filter.sourceId ? filter.sourceId : ""}
                                       onBlur={() => handleSearchInSourceIdFilterClickedAway(filter, navigate)}
                                       onChange={e =>
                                           handleSearchInSourceIdFilterChanged(e, filter, setFilter)}
                                       onKeyDown={e =>
                                           handleKeyDownOnSearchInSourceIdFilter(e, filter, navigate)}
                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position={"start"}>
                                                   <Search/>
                                               </InputAdornment>
                                           )
                                       }}/>
                        </Stack>
                    </Collapse>
                    <ListItemButton onClick={() =>
                        handleGroupClicked(filterMenuSegmentStatusOpen, setFilterMenuSegmentStatusOpen)}>
                        <ListItemText primary={"Segment status"}/>
                        {filterMenuSegmentStatusOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={filterMenuSegmentStatusOpen} key={"segment-status-collapse"}>
                        <Stack padding={1}>
                            <FormControl fullWidth variant={"standard"}>
                                <InputLabel>Workflow stages</InputLabel>
                                <Select multiple value={selectedWorkflowSteps.toArray()}
                                        onChange={e => handleWorkflowFilterChanged(e, filter, navigate)}
                                        renderValue={(selected) => selected.map(id => {
                                            if (!project)
                                                return;
                                            const found = project.workflow
                                                .find(step => step.id === Number(id));
                                            return !found ? id : found.name;
                                        }).join(', ')} variant={'outlined'}>
                                    {project && project.workflow.map(workflowStep =>
                                        <MenuItem key={workflowStep.id} value={workflowStep.id}>
                                            <Checkbox checked={
                                                !workflowStep.id
                                                    ? false
                                                    : selectedWorkflowSteps.indexOf(workflowStep.id) > -1
                                            }/>
                                            <ListItemText>{workflowStep.name}</ListItemText>
                                        </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Collapse>
                    <ListItemButton
                        onClick={() => handleGroupClicked(filterMenuCommentsOpen, setFilterMenuCommentsOpen)}>
                        <ListItemText primary={"Comments"}/>
                        {filterMenuCommentsOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={filterMenuCommentsOpen} key={"comments-collapse"}>
                        <Stack padding={1}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={filter.withComments}
                                                                     onChange={e =>
                                                                         handleWithCommentsFilterChanged(
                                                                             e,
                                                                             filter,
                                                                             navigate)}/>}
                                                  label={"With comments"}/>
                            </FormGroup>
                        </Stack>
                    </Collapse>
                    <ListItemButton
                        onClick={() => handleGroupClicked(filterMenuLocksOpen, setFilterMenuLocksOpen)}>
                        <ListItemText primary={"Locks"}/>
                        {filterMenuLocksOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={filterMenuLocksOpen} key={"lock-collapse"}>
                        <Stack padding={1}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={filter.withLocked}
                                                                     onChange={e =>
                                                                         handleWithLockedFilterChanged(
                                                                             e,
                                                                             filter,
                                                                             navigate)}/>}
                                                  label={"Locked segments"}/>
                                <FormControlLabel control={<Checkbox checked={filter.withUnlocked}
                                                                     onChange={e =>
                                                                         handleWithUnlockedFilterChanged(
                                                                             e,
                                                                             filter,
                                                                             navigate)}/>}
                                                  label={"Unlocked segments"}/>
                            </FormGroup>
                        </Stack>
                    </Collapse>
                    <ListItemButton onClick={() => handleGroupClicked(filterMenuAuthorOpen, setFilterMenuAuthorOpen)}>
                        <ListItemText primary={"Author"}/>
                        {filterMenuAuthorOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={filterMenuAuthorOpen} key={"author-collapse"}>
                        <Stack padding={1}>
                            {<Autocomplete multiple size={"small"} options={users} disableCloseOnSelect
                                           getOptionLabel={option => option.name}
                                           renderOption={(props, option, {selected}) =>
                                               <li {...props}>
                                                   <Checkbox icon={icon} checkedIcon={checkedIcon}
                                                             checked={selected}/>
                                                   {option.name}
                                               </li>}
                                           style={{minWidth: 300, maxWidth: 500}}
                                           renderInput={params =>
                                               <Stack>
                                                   <TextField {...params}/>
                                                   <Stack direction={"row"} justifyContent={"flex-end"}>
                                                       <Button size={"small"} onClick={() =>
                                                           handleAuthorsFilterChanged(users, filter, navigate)}>
                                                           Select all
                                                       </Button>
                                                       <Button size={"small"} onClick={() =>
                                                           handleAuthorsFilterChanged([], filter, navigate)}>
                                                           Clear all
                                                       </Button>
                                                   </Stack>
                                               </Stack>}
                                           onChange={(_e, value) =>
                                               handleAuthorsFilterChanged(value, filter, navigate)}
                                           value={users.filter(user =>
                                               filter.authors ? filter.authors.contains(user.id) : false)}/>}
                        </Stack>
                    </Collapse>
                    <ListItemButton onClick={() => handleGroupClicked(filterMenuTimeOpen, setFilterMenuTimeOpen)}>
                        <ListItemText primary={"Time"}/>
                        {filterMenuTimeOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={filterMenuTimeOpen} key={"time-collapse"}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack>
                                <Stack direction={"row"} spacing={0.5} padding={1}>
                                    <DateTimePicker label={"From"}
                                                    ampm={false}
                                                    maxDate={filter.to != null ? dayjs(filter.to) : undefined}
                                                    onChange={value => handleTimeFilterChanged(
                                                        value,
                                                        dayjs(filter.to),
                                                        filter,
                                                        navigate)}
                                                    value={dayjs(filter.from)}/>
                                    <DateTimePicker label={"To"}
                                                    ampm={false}
                                                    minDate={filter.from != null ? dayjs(filter.from) : undefined}
                                                    onChange={value => handleTimeFilterChanged(
                                                        dayjs(filter.from),
                                                        value,
                                                        filter,
                                                        navigate)}
                                                    value={dayjs(filter.to)}/>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"flex-end"}>
                                    <Button size={"small"} onClick={() =>
                                        handleTimeFilterChanged(null, null, filter, navigate)}>
                                        Reset
                                    </Button>
                                </Stack>
                            </Stack>
                        </LocalizationProvider>
                    </Collapse>
                </List>
            </Menu>
        </Stack>
    );
}

function handleFilterButtonClicked(event: React.MouseEvent<HTMLElement>,
                                   setFilterMenuAnchor: (value: HTMLElement | null) => void,
                                   setFilterMenuOpen: (value: boolean) => void) {
    setFilterMenuAnchor(event.currentTarget);
    setFilterMenuOpen(true);
}

function handleGroupClicked(state: boolean, setState: (value: boolean) => void) {
    setState(!state);
}

function handleFilterMenuClosed(setFilterMenuAnchor: (value: HTMLElement | null) => void,
                                setFilterMenuOpen: (value: boolean) => void) {
    setFilterMenuAnchor(null);
    setFilterMenuOpen(false);
}

function handleWorkflowFilterChanged(event: SelectChangeEvent<number[]>,
                                     filter: SegmentListFilter,
                                     navigate: NavigateFunction) {
    const {
        target: {value}
    } = event;
    const workflow = typeof value === 'string' ? value.split(',') : value;
    const updatedFilter = filter.set("workflowIds", Immutable.List(workflow.map(id => Number(id))));
    updatePage(navigate, updatedFilter);
}

function handleSearchInSourceIdFilterChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                             filter: SegmentListFilter,
                                             setFilter: (value: SegmentListFilter) => void) {
    const updatedFilter = filter.set("sourceId", event.target.value);
    setFilter(updatedFilter);
}

function handleWithCommentsFilterChanged(event: React.ChangeEvent<HTMLInputElement>,
                                         filter: SegmentListFilter,
                                         navigate: NavigateFunction) {
    const updatedFilter = filter.setWithComments(event.target.checked);
    updatePage(navigate, updatedFilter);
}

function handleWithLockedFilterChanged(event: React.ChangeEvent<HTMLInputElement>,
                                       filter: SegmentListFilter,
                                       navigate: NavigateFunction) {
    const updatedFilter = filter.setWithLocked(event.target.checked);
    updatePage(navigate, updatedFilter);
}

function handleWithUnlockedFilterChanged(event: React.ChangeEvent<HTMLInputElement>,
                                         filter: SegmentListFilter,
                                         navigate: NavigateFunction) {
    const updatedFilter = filter.setWithUnlocked(event.target.checked);
    updatePage(navigate, updatedFilter);
}

function handleAuthorsFilterChanged(value: User[], filter: SegmentListFilter, navigate: NavigateFunction) {
    const updatedFilter = filter.set("authors", Immutable.List(value.map(user => user.id)));
    updatePage(navigate, updatedFilter);
}

function handleTimeFilterChanged(from: Dayjs | null,
                                 to: Dayjs | null,
                                 filter: SegmentListFilter,
                                 navigate: NavigateFunction) {
    const updatedFilter = filter
        .set("from", from && from.isValid() ? from.toDate() : null)
        .set("to", to && to.isValid() ? to.toDate() : null);
    updatePage(navigate, updatedFilter);
}

function handleSearchInSourceIdFilterClickedAway(filter: SegmentListFilter, navigate: NavigateFunction) {
    updatePage(navigate, filter);
}

function handleKeyDownOnSearchInSourceIdFilter(event: React.KeyboardEvent<HTMLDivElement>,
                                               filter: SegmentListFilter,
                                               navigate: NavigateFunction) {
    event.stopPropagation();
    if (event.key === "Enter") {
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement) {
            activeElement.blur();
            updatePage(navigate, filter);
        }
    }
}

function handleFilterDeleted(name: keyof ISegmentListFilter, filter: SegmentListFilter, navigate: NavigateFunction) {
    updatePage(navigate, filter.set(name, null));
}

function drawSelectedFilters(project: Project,
                             users: User[],
                             filter: SegmentListFilter,
                             selectedWorkflowSteps: Immutable.List<number>,
                             navigate: NavigateFunction) {
    const filters: React.JSX.Element[] = [];

    if (filter.source) {
        const title = filter.sourceMatchCase ? "Source (match-case)" : "Source";
        filters.push(drawSelectedFilter(title + ": " + filter.source, "source", "source", filter, navigate));
    }

    if (filter.target) {
        const title = filter.targetMatchCase ? "Target (match-case)" : "Target";
        filters.push(drawSelectedFilter(title + ": " + filter.target, "target", "target", filter, navigate));
    }

    if (filter.sourceId)
        filters.push(drawSelectedFilter("Source ID: " + filter.sourceId, "sourceId", "sourceId", filter, navigate));

    const selectedWorkflowStepsArray: string[] = [];
    selectedWorkflowSteps.forEach(workflowStepId => {
        const foundWorkflowStep = project.workflow.find(workflowStep => workflowStep.id === Number(workflowStepId));
        if (foundWorkflowStep)
            selectedWorkflowStepsArray.push(foundWorkflowStep.name);
    });
    if (selectedWorkflowStepsArray.length > 0) {
        const chipText = "Workflows: " + selectedWorkflowStepsArray.join(", ");
        filters.push(drawSelectedFilter(chipText, "workflowIds", "workflowIds", filter, navigate));
    }

    if (filter.withComments)
        filters.push(drawSelectedFilter("With comments", "withComments", "withComments", filter, navigate));

    if (filter.withLocked)
        filters.push(drawSelectedFilter("Locked segments", "withLocked", "withLocked", filter, navigate));

    if (filter.withUnlocked)
        filters.push(drawSelectedFilter("Unlocked segments", "withUnlocked", "withUnlocked", filter, navigate));

    const authorsFilter = drawAuthorsFilter(filter, users, navigate);
    if (authorsFilter)
        filters.push(authorsFilter);

    if (filter.from)
        filters.push(drawSelectedFilter("From: " + filter.from, "from", "from", filter, navigate));

    if (filter.to)
        filters.push(drawSelectedFilter("To: " + filter.to, "to", "to", filter, navigate));

    return filters;
}

function drawAuthorsFilter(filter: SegmentListFilter, users: User[], navigate: NavigateFunction) {
    if (!filter.authors)
        return;
    const selectedUsersArray: string[] = [];
    filter.authors.forEach(userId => {
        const foundUser = users.find(user => user.id === userId);
        if (foundUser)
            selectedUsersArray.push(foundUser.name);
    });
    if (selectedUsersArray.length > 0) {
        const chipText = "Users: " + selectedUsersArray.join(", ");
        return drawSelectedFilter(chipText, "authors", "authors", filter, navigate);
    }
    return null;
}

function drawSelectedFilter(chipText: string,
                            filterName: keyof ISegmentListFilter,
                            key: string,
                            filter: SegmentListFilter,
                            navigate: NavigateFunction) {
    return <Tooltip title={chipText}>
        <CustomChip size={"small"} label={chipText}
                    onDelete={() => handleFilterDeleted(filterName, filter, navigate)}
                    key={key + "-filter-chip"}/>
    </Tooltip>;
}

function getAuthorOptions(administrators: Immutable.List<User> | undefined,
                          usersWithPermissions: Immutable.List<User> | undefined) {
    const options: User[] = [];
    if (administrators)
        administrators.forEach(administrator => options.push(administrator));
    if (usersWithPermissions) {
        usersWithPermissions.forEach(user => {
            const foundUser = options.find(u => u.id === user.id);
            if (!foundUser)
                options.push(user);
        });
    }
    return options;
}

async function updatePage(navigate: NavigateFunction, updatedFilter: SegmentListFilter) {
    const currentSegment = segmentEditorStore.getState().segment;
    const url = getSegmentPath(updatedFilter, updatedFilter.isEmpty && currentSegment ? currentSegment.order : null);
    navigate(url, {replace: true});
}