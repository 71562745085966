import {
    deleteTranslations,
    fetchGroupedSegments,
    fetchSegments,
    lockTranslations,
    setWorkflow,
    unlockTranslations
} from "../../../api/SegmentApi";
import {dispatcher} from "../../Dispatcher";
import segmentListStore from "./SegmentListStore";
import {fetchProjectAction} from "../../project/page/ProjectPageActions";
import {fetchFile} from "../../../api/FileApi";
import {setSegmentAction} from "../editor/SegmentEditorActions";
import {Pageable} from "../../../model/Pageable";
import SegmentListFilter from "./SegmentListFilter";
import {Segment} from "../../../model/Segment";
import {fetchProjectListAction} from "../../project/list/ProjectListActions";
import Translation from "../../../model/Translation";
import {Language} from "../../../model/Language";
import {CommonListActions} from "../../common/list/CommonListActions";
import {AvailableResourceType} from "../../../model/AvailableResources";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import {List} from "immutable";
import GroupedListItem from "../../../model/GropedListItem";
import {fetchSupportedLanguages} from "../../../api/LocaleApi";
import {
    deselectSegment,
    selectSegment,
    setIsUpdating,
    setLastClickedSegment,
    setSegmentListPage,
    toggleClearTargetAlert,
    toggleSelectAll,
    toggleWithControls
} from "./SegmentListPayload";
import {CatFile} from "../../../model/CatFile";
import {Page} from "../../../model/Page";
import {catFileListActions} from "../../cat-files/CatFileListActions";
import CatFilesFilter from "../../cat-files/CatFilesFilter";
import segmentEditorStore from "../editor/SegmentEditorStore";
import {fetchProjectUsersAction} from "../../users/list/UsersListActions";
import {SelectionModel} from "../../../model/SelectionModel";

export function updateCurrentSegmentInListAction(updater: (value: Segment | undefined) => Segment | undefined) {
    const segmentListState = segmentListStore.getState();
    const segmentListPage = segmentListState.page;
    const segmentListFilter = segmentListState.filter;
    const segmentList = segmentListPage.list;
    const currentSegment = segmentEditorStore.getState().segment;
    if (!currentSegment)
        return;
    const indexToUpdate = segmentList.findIndex(item => item.get("id") === currentSegment.id);
    dispatcher.dispatch(setSegmentListPage(
        segmentListPage.setList(segmentList.update(indexToUpdate, updater)),
        segmentListFilter));
}

export async function setSegmentListPageAction(pageable: Pageable, filter?: SegmentListFilter) {
    const currentFilter = filter ? filter : segmentListStore.getState().filter;
    const segments = await fetchSegments(currentFilter, pageable);
    dispatcher.dispatch(setSegmentListPage(segments, currentFilter));
    return segments;
}

export async function refreshSegmentListPageAction() {
    const state = segmentListStore.getState();
    await setSegmentListPageAction(state.page.pageable, state.filter)
}

export function updateSegmentTranslationAction(segmentId: number, translation: Translation) {
    const state = segmentListStore.getState();
    const page = state.page;
    const updated = page
        .list
        .map(value => {
            if (value.id !== segmentId)
                return value;

            if (translation.equals(Translation.UnavailableTranslation))
                return null;

            return value.set("translation", translation);
        })
        .filter(value => value)
        .map(value => value as Segment);

    dispatcher.dispatch(setSegmentListPage(page.setList(updated), state.filter));
}

export async function deleteSegmentTranslationsAction(selection: SelectionModel) {
    dispatcher.dispatch(setIsUpdating(true));
    const state = segmentListStore.getState();
    await deleteTranslations(selection, state.filter);
    await refreshSegmentListPageAction();
    dispatcher.dispatch(setIsUpdating(false));
}

export async function initSegmentListEditor(projectId: number,
                                            language: string,
                                            catFileId: number,
                                            currentPosition: number | null,
                                            searchParams: URLSearchParams) {
    const listState = segmentListStore.getState();
    const currentFilter = listState.filter;
    const currentPage = listState.page;

    if (currentFilter.projectId !== projectId) {
        fetchProjectListAction();
        fetchSupportedLanguages();
        fetchProjectAction(projectId);
        fetchProjectUsersAction(projectId);
        catFileListActions.fetch(new CatFilesFilter({projectId: projectId}));
    }

    let catFile: CatFile | undefined = currentFilter.nonNullCatFile;
    if (catFile.id !== catFileId)
        catFile = await fetchFile(catFileId, Language.fromSupported(language));
    if (!catFile)
        return;

    const pageable = currentPage.pageable;
    let filter = SegmentListFilter.fromSearchParams(catFile, language, searchParams);

    // TODO: fix page selection
    if (currentPosition) {
        const pageNumber = Math.floor((currentPosition - 1) / pageable.size) + 1;
        const isFilterEmpty = filter.isEmpty;
        if (!filter.equals(currentFilter) || isFilterEmpty && pageable.number !== pageNumber) {
            await setSegmentListPageAction(isFilterEmpty ? pageable.set("number", pageNumber) : pageable, filter);
        }
        setSegmentListPositionAction(language, currentPosition - 1);
    } else {
        await setSegmentListPageAction(pageable.set("number", 1), filter);
        setSegmentListPositionAction(language, 0);
    }
}

export function resetSegmentListEditor() {
    dispatcher.dispatch(setSegmentListPage(new Page<Segment>(), new SegmentListFilter()));
}

export async function setSegmentListPositionAction(language: string, position: number) {
    const listState = segmentListStore.getState();

    const segments = listState.page.list;
    if (!segments)
        return;
    let foundSegment = segments.find(segment => segment.order === position);
    if (foundSegment)
        await setSegmentAction(foundSegment, listState.filter.nonNullCatFile, language);
}

export function toggleSelectAllAction() {
    dispatcher.dispatch(toggleSelectAll());
}

export function selectSegmentAction(id: number) {
    dispatcher.dispatch(selectSegment(id));
}

export function deselectSegmentAction(id: number) {
    dispatcher.dispatch(deselectSegment(id));
}

export function toggleWithControlsAction() {
    dispatcher.dispatch(toggleWithControls());
}

export function toggleClearTargetAlertAction() {
    dispatcher.dispatch(toggleClearTargetAlert());
}

export function setLastClickedSegmentAction(id: number) {
    dispatcher.dispatch(setLastClickedSegment(id));
}

export async function lockTranslationsAction(isLocking: boolean) {
    const state = segmentListStore.getState();

    dispatcher.dispatch(setIsUpdating(true));

    if (isLocking)
        await lockTranslations(state.selection, state.filter);
    else
        await unlockTranslations(state.selection, state.filter);

    await refreshSegmentListPageAction();

    dispatcher.dispatch(setIsUpdating(false));
}

export async function setWorkflowAction(workflowStepId: number) {
    const state = segmentListStore.getState();

    dispatcher.dispatch(setIsUpdating(true));

    await setWorkflow(state.selection, state.filter, workflowStepId);

    await refreshSegmentListPageAction();

    dispatcher.dispatch(setIsUpdating(false));
}

export const flatGroupedSegmentListActions
    = new CommonListActions<GroupedListItem<Segment>, SegmentListFilter>(
    fetchGroupedSegments,
    async () => dispatchUnsupportedOperation(),
    async () => {
        dispatchUnsupportedOperation();
        return List<GroupedListItem<Segment>>();
    },
    AvailableResourceType.Segments)
