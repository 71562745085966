import {Button, ButtonGroup, DialogActions, FormControl, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import {FilterAlt, LockOpen} from "@mui/icons-material";
import Checkbox from '@mui/material/Checkbox';
import LockIcon from "@mui/icons-material/Lock";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {
    deleteSegmentTranslationsAction,
    deselectSegmentAction,
    lockTranslationsAction,
    selectSegmentAction,
    setWorkflowAction,
    toggleClearTargetAlertAction,
    toggleSelectAllAction,
    toggleWithControlsAction
} from "../../../../flux/segment/list/SegmentListActions";
import {SelectionModel} from "../../../../model/SelectionModel";
import segmentListStore from "../../../../flux/segment/list/SegmentListStore";
import {Segment} from "../../../../model/Segment";
import {Page} from "../../../../model/Page";
import {LoadingButton} from "@mui/lab";
import PrivateComponent from "../../../PrivateComponent";
import {AvailableResourceType} from "../../../../model/AvailableResources";
import {eventListActions} from "../../../../flux/event/EventListActions";
import projectPageStore from "../../../../flux/project/page/ProjectPageStore";
import {ReactSetter} from "../../../../globals/Types";

export default function SegmentListControls() {
    const lastStep = projectPageStore
        .getState()
        .project
        .getFirstStep();

    const [selection, setSelection] = useState(new SelectionModel());
    const [page, setPage] = useState(new Page<Segment>());
    const [workflowId, setWorkflowId] = useState(lastStep?.id ?? 0);

    useEffect(() => {
        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            setSelection(state.selection);
            setPage(state.page);
        });

        return () => {
            segmentListListener.remove();
        };
    });

    return (
        <Stack direction={"row"} spacing={1} padding={'16px'}>
            {clearTargetAlert(selection, page)}
            {drawControls(selection, page, workflowId, setWorkflowId)}
            <Box flexGrow={1}/>
            <Button variant={"text"}
                    onClick={() => toggleWithControlsAction()}
                    startIcon={<FilterAlt/>}>
                {`${segmentListStore.getState().withControls ? "Hide" : "Show"} controls `}
            </Button>
        </Stack>
    )
}

function clearTargetAlert(selection: SelectionModel, page: Page<Segment>) {
    const totalElements = page.totalElements;
    const selectedSegmentsCounter = selection.countSelected(totalElements);
    let clearAlertTitle;
    const clearAlertTitleConst = "You are about to clear translation in the selected ";
    const state = segmentListStore.getState();
    if (selectedSegmentsCounter == 0 && state.clearTargetAlertOpen)
        toggleClearTargetAlertAction();
    if (selection.isSelectAll)
        clearAlertTitle = "You are about to clear translations in all filtered rows. ";
    else if (selectedSegmentsCounter == 1)
        clearAlertTitle = clearAlertTitleConst + "row. ";
    else
        clearAlertTitle = clearAlertTitleConst + "rows. ";

    clearAlertTitle += "Please reconsider this action.";

    return (
        <Dialog
            open={state.clearTargetAlertOpen}
            aria-labelledby="clear-target-alert-dialog-title"
            aria-describedby="clear-target-alert-dialog-description">
            <DialogTitle id="clear-target-alert-dialog-title">
                {clearAlertTitle}
            </DialogTitle>
            <DialogActions>
                <Button onClick={() => {
                    toggleClearTargetAlertAction();
                    deleteSegmentTranslationsAction(selection).then();
                    eventListActions.refresh(true).then();
                }}>
                    Continue
                </Button>
                <Button onClick={() => toggleClearTargetAlertAction()} autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function drawControls(selection: SelectionModel,
                      page: Page<Segment>,
                      workflowId: number,
                      setWorkflowId: ReactSetter<number>) {
    if (!segmentListStore.getState().withControls)
        return null;
    const inProgress = segmentListStore.getState().longTasks.isUpdatingSegments;
    return (
        <Stack direction={"row"} spacing={0.5}>
            <Box display="flex" alignItems="center">
                {drawCheckbox(selection, page)}
                {selection.countSelected(page.totalElements)}
            </Box>
            <Button variant={"text"}
                    onClick={() => toggleSelectAllAction()}>
                {`${selection.isSelectAll ? "Clear" : "Select"} All `}
            </Button>
            <PrivateComponent resource={AvailableResourceType.ClearTarget} writeAllow={true}>
                <LoadingButton variant={"contained"}
                               loading={inProgress}
                               onClick={() => toggleClearTargetAlertAction()}>
                    Clear target
                </LoadingButton>
            </PrivateComponent>
            {drawLockButtons()}
            {drawSetWorkflowStep(workflowId, setWorkflowId)}
        </Stack>
    )
}

function drawSetWorkflowStep(workflowId: number, setWorkflowId: ReactSetter<number>) {
    const state = segmentListStore.getState();
    const inProgress = state.longTasks.isUpdatingSegments;

    return (
        <Stack direction={"row"} spacing={2}>
            <Box sx={{minWidth: 150}}>
                <FormControl fullWidth>
                    <InputLabel id="select-workflow-to-change-label" size={'normal'}>Modify selected</InputLabel>
                    <Select
                        labelId={"select-workflow-to-change-label"}
                        id="select-workflow-to-change"
                        value={workflowId}
                        label={"Modify selected"}
                        onChange={event => setWorkflowId(event.target.value as number)}
                    >
                        {drawWorkflowMenuItems()}
                    </Select>
                </FormControl>
            </Box>
            <LoadingButton variant={"contained"}
                           loading={inProgress}
                           onClick={() => setWorkflowAction(workflowId)}
            >
                Apply
            </LoadingButton>
        </Stack>
    )
}

function drawWorkflowMenuItems() {
    return projectPageStore
        .getState()
        .project
        .getWorkflowSteps()
        .map(step =>
            <MenuItem value={step.id} id={`workflow-select-item ${step.id}`}>
                {step.name}
            </MenuItem>)
}

function drawLockButtons() {
    const state = segmentListStore.getState();
    const inProgress = state.longTasks.isUpdatingSegments;

    return (
        <PrivateComponent resource={AvailableResourceType.SegmentsLock} writeAllow={true}>
            <ButtonGroup variant="text">
                <LoadingButton
                    loading={inProgress}
                    loadingPosition={"start"}
                    startIcon={<LockIcon/>}
                    onClick={() => lockTranslationsAction(true)}>
                    Lock
                </LoadingButton>
                <LoadingButton
                    loading={inProgress}
                    loadingPosition={"start"}
                    startIcon={<LockOpen/>}
                    onClick={() => lockTranslationsAction(false)}>
                    Unlock
                </LoadingButton>
            </ButtonGroup>
        </PrivateComponent>
    )
}

function drawCheckbox(selection: SelectionModel, page: Page<Segment>) {
    let isIndeterminate = false;
    let isCheckedAll = true;

    page.list.forEach(value => {
        if (!selection.isSelected(value.id))
            isCheckedAll = false;

        if (selection.isSelected(value.id))
            isIndeterminate = true;
    });

    return <Checkbox onChange={(_ignored, checked) => selectPage(page, checked)}
                     indeterminate={isIndeterminate && !isCheckedAll}
                     checked={isCheckedAll}/>;
}

function selectPage(page: Page<Segment>, checked: boolean) {
    page.list.forEach(segment => checked ? selectSegmentAction(segment.id) : deselectSegmentAction(segment.id))
}
